<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        :disabled="options.page != 1"
        :title="options.page == 1 ? '' : 'A frissítés opció csak az első oldalon működik'"
        v-bind="$config.buttonAttrs.floating"
        @click="togglePolling"
        :loading="loading"
      >
        <v-icon v-if="polling">mdi-stop</v-icon>
        <v-icon v-else>mdi-play</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Parkoló napló</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :md="3" :lg="2">
              <v-autocomplete
                label="Parkoló"
                :items="buildings"
                item-text="name"
                item-value="id"
                dense
                clearable
                hide-details
                v-model="search.building_id"
                placeholder="Összes"
                multiple
                @change="searchChangeEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="2">
              <v-autocomplete
                label="Kontroller"
                :items="controllers"
                item-text="name"
                item-value="element_id"
                dense
                clearable
                hide-details
                v-model="search.controller_id"
                placeholder="Összes"
                multiple
                @change="searchChangeEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="2">
              <v-autocomplete
                label="Típus"
                :items="logTypes"
                item-text="label"
                item-value="value"
                dense
                hide-details
                clearable
                v-model="search.log_type"
                placeholder="Összes"
                @change="searchChangeEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="3">
              <v-text-field
                label="Név / Kártyaszám / Vonalkód száma / Rendszám"
                dense
                clearable
                hide-details
                v-model="search.keyphrase"
                @input="searchChangeEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="search.period === 'custom' ? 1 : 2">
              <v-select
                label="Időkorlát"
                :items="timePeriods"
                item-text="label"
                item-value="value"
                dense
                hide-details
                v-model="search.period"
                @change="searchChangeEventHandler"
              />
            </v-col>
            <template v-if="search.period === 'custom'">
              <v-col :cols="12" :md="3" :lg="2">
                <v-datetime-picker
                  label="Időkorlát kezdete"
                  v-model="search.from"
                  clearable
                  dense
                  :date-picker-props="{ allowedDates: allowedStartDates }"
                  :time-picker="false"
                  @change="searchChangeEventHandler"
                  :error-messages="search.from ? '' : 'Kötelező!'"
                />
              </v-col>
              <v-col :cols="12" :md="2" :lg="2">
                <v-datetime-picker
                  label="Időkorlát vége"
                  v-model="search.to"
                  clearable
                  dense
                  :date-picker-props="{ allowedDates: allowedEndDates }"
                  :time-picker="false"
                  :disabled="!search.from"
                  @change="searchChangeEventHandler"
                  :error-messages="search.from && !search.to ? 'Kötelező!' : ''"
                />
              </v-col>
            </template>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="dataTable.loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.timestamp`]="{ item }">
          <span :title="item.timestamp | moment($config.momentFormats.explicit)">{{
            item.timestamp | moment('lll')
          }}</span>
        </template>
        <template #[`item.number`]="{ item }">
          {{ item.card_number ? item.card_rule_prefix + item.card_number : item.barcode_number }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="item.element_id && hasRight('CARD_READ')"
            :to="{ name: 'CardEditor', params: { id: item.element_id } }"
            fab
            small
            color="secondary"
            dark
            text
            class="rounded-sm"
          >
            <v-icon>mdi-card-account-details</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      polling: false,
      buildingType: 'P',
      waiting: false,
      routeName: 'ParkingLog',
      fetchItems: this.fetchParkingLog,
      dataTable: {
        loading: false,
        options: {
          sortBy: ['logid'],
          sortDesc: [true],
        },
        headers: [
          { text: '#', value: 'logid' },
          { text: 'Idő', value: 'timestamp' },
          { text: 'Név', value: 'creator_name' },
          { text: 'Kártyaszám', value: 'card_number', width: 120 },
          { text: 'Hely', value: 'gatename' },
          { text: 'Station ID', value: 'stationid', sortable: false, width: 120 },
          { text: 'Gate ID', value: 'gateid', sortable: false, width: 90 },
          { text: 'Típus', value: 'type', width: 90, sortable: false },
          { text: 'Esemény', value: 'message', sortable: false },
          { value: 'actions', sortable: false },
        ],
      },
      timePeriods: [
        {
          label: 'Utolsó 24 óra',
          value: '1 days',
        },
        {
          label: 'Utolsó hét',
          value: '1 weeks',
        },
        {
          label: 'Utolsó hónap',
          value: '1 months',
        },
        {
          label: 'Egyéni',
          value: 'custom',
        },
      ],
      search: {
        keyphrase: '',
        log_type: 'enter',
        building_id: [],
        building_type: 'P',
        period: '1 days',
        controller_id: [],
        from: '',
        to: '',
      },
      logs: [],
      pollingInterval: null,
      pollingTimeout: null,
      pollingPausedByPagination: false,
      firstFetch: true,
    };
  },

  created() {
    if (this.options.page == 1) {
      this.polling = true;
    }
  },

  beforeDestroy() {
    this.polling = false;
    clearTimeout(this.pollingTimeout);
  },

  watch: {
    'dataTable.options': {
      handler(val) {
        if (val.page > 1) {
          if (this.polling) {
            clearTimeout(this.pollingTimeout);
            this.polling = false;
            this.pollingPausedByPagination = true;
          }
        } else if (this.pollingPausedByPagination) {
          this.fetchParkingLog(null, true);
          this.polling = true;
          this.pollingPausedByPagination = false;
        }
      },
      deep: true,
    },

    search: {
      handler() {
        this.dataTable.loading = true;
      },
      deep: true,
    },
  },

  methods: {
    parseSearchQueries() {
      return {
        keyphrase: this.$route.query.keyphrase,
        log_type: this.$route.query.log_type,
        period: this.$route.query.period,
        controller_id: this.$route.query.controller_id
          ? this.toArray(this.$route.query.controller_id)
          : [],
        building_id: this.$route.query.building_id
          ? this.toArray(this.$route.query.building_id)
          : [],
        from: this.$route.query.from,
        to: this.$route.query.to,
      };
    },

    toArray(param) {
      return Array.isArray(param) ? param : [param];
    },

    togglePolling() {
      this.polling = !this.polling;
      if (this.polling) {
        this.fetchParkingLog(null, true);
      } else {
        clearTimeout(this.pollingTimeout);
      }
    },

    allowedStartDates(val) {
      const now = new Date();
      const startDate = new Date(val);
      if (this.search.to?.length) {
        let intervalStart = new Date(this.search.to);
        intervalStart.setMonth(intervalStart.getMonth() - 2);
        return (
          startDate.getTime() <= now.getTime() && startDate.getTime() >= intervalStart.getTime()
        );
      }
      return startDate.getTime() <= now.getTime();
    },

    allowedEndDates(val) {
      const now = new Date();
      const startDate = new Date(this.search.from);
      const endDate = new Date(val);
      let intervalEnd = new Date(this.search.from);
      intervalEnd.setMonth(intervalEnd.getMonth() + 2);
      intervalEnd = Math.min(intervalEnd.getTime(), now.getTime());
      return endDate.getTime() >= startDate.getTime() && endDate.getTime() <= intervalEnd;
    },

    searchChangeEventHandler(val) {
      if (
        this.search.period === 'custom' &&
        (!this.search.from?.length || !this.search.to?.length)
      ) {
        return;
      }
      this.searchEventHandler();
    },

    async fetchParkingLog(origin = null, keepPolling = false) {
      if (origin === 'searchEventHandler' || this.firstFetch) {
        this.dataTable.loading = true;
      }

      if (this.firstFetch) {
        keepPolling = this.polling;
        this.firstFetch = false;
      }

      this.loading = true;
      try {
        this.abortController = new AbortController();
        const response = await this.$http.post('logs/list/building', this.postData, { signal: this.abortController.signal });
        if (this.polling && keepPolling) {
          this.pollingTimeout = setTimeout(() => {
            this.fetchParkingLog(null, true);
          }, 2000);
        }
        if (response) {
          this.dataTable.items = response.logs;
          this.dataTable.itemsLength = response.logs_count;
          this.dataTable.options.page = this.$route.query.page * 1 || 1;
        }
      } catch (e) {
        this.polling = false;
        console.error(e);
      } finally {
        this.loading = false;
        this.dataTable.loading = false;
        this.abortController = null;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        keyphrase: this.search.keyphrase || '',
        period: this.search.period || '',
        log_type: this.search.log_type || '',
        building_id: this.search.building_id.map((e) => Number(e)) || [],
        controller_id: this.search.controller_id.map((e) => Number(e)) || [],
        building_type: this.search.building_type || '',
        from: this.search.from || '',
        to: this.search.to || '',
      };
    },
  },

  asyncComputed: {
    buildings: {
      async get() {
        const response = await this.$http.post('buildings/list', {
          type: 'P', // Épületek
        });
        return response.buildings;
      },
      default: [],
    },
    controllers: {
      async get() {
        const response = await this.$http.post('controllers/list', {
          enabled: true
        });
        return response.controllers;
      },
      default: [],
    },
    logTypes: {
      async get() {
        const response = await this.$http.post('daemon-logs/types/list');
        return response.log_types;
      },
      default: [],
    },
  },
};
</script>

